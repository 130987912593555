export default [
    {
        path: "/login",
        name: "login",
        component: () => import('v/login/Login'),
        meta: {
            title: "北航 | 2024年强基计划招生考试初试考点城市选择系统",
            authorization: false
        }
    },
];
